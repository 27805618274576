import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
/* import IdentificationFormComponent from '@/modules/account-details/components/IdentificationFormComponent.vue'; */
import UserVerificationStatus from '@/core/components/account/UserVerificationStatus.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'identification-page',
    components: {
        CardWrapper,
        UserVerificationStatus,
    },
    data() {
        return {
            submittingForm: false,
            UserVerificationStatusEnum,
            moveToNextSection: false,
            verificationRedirectUrl: null,
            showIframeForm: false,
            identification: {
                transactionReference: '',
                scanReference: '',
                timestamp: null,
                document: null,
                verification: null,
                type: null,
                userVerificationStatus: UserVerificationStatusEnum.NOT_ADDED,
            }
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        disabled() {
            return this.user.userVerificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
        }
    },
    created() {
        if (this.user?.userIdentification) {
            this.getUserIdentification({ userId: this.user.id, userIdentificationId: this.user.userIdentification.id })
                .then(async (response) => {
                this.identification = response;
                await this.getAccountStatuses();
            });
        }
    },
    methods: {
        ...mapActions(USERS_STORE, ['getUserIdentification', 'initiateUserIdentification', 'getAccountStatuses']),
        nextSection() {
            this.moveToNextSection = true;
            this.$router.push({ name: 'account/certification' });
        },
        submit() {
            this.submittingForm = true;
            const payload = {
                userId: this.user.id
            };
            this.initiateUserIdentification(payload)
                .then((response) => {
                if (response && response.redirectUrl) {
                    this.verificationRedirectUrl = response.redirectUrl;
                    window.open(response.redirectUrl);
                    this.showIframeForm = true;
                }
            })
                .finally(() => {
                this.submittingForm = false;
            });
        }
    }
});
